<template lang="pug">
.dashboard
  .dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
    Sidebar-user
  .dashboard-content
    b-container
      list-credit-admin
</template>
<script>
import { mapActions } from "vuex";
import SidebarUser from "@/components/dashboard/SidebarUser";
import FooterNavbarMobile from "@/components/FooterNavbarMobile";
import ListCreditAdmin from "@/components/credit/ListCreditComponent";

export default {
  name: "IndexCredit",
  components: {
    SidebarUser,
    FooterNavbarMobile,
    ListCreditAdmin
  },
  created() {
    this.checkIsRole();
  },
  methods: {
    ...mapActions("auth", ["checkIsRole"]),
  },
}
</script>
<style lang="scss">
.dashboard {
  min-height: calc(100vh - 196.66px);
}
</style>